import { useState } from "react";
import { Link } from "react-router-dom";
import SettingsModal from "./SettingsModal";
import {BsGearFill} from "react-icons/bs";

export default function Header({title}){

    const authorization = localStorage.getItem("authorization");
    const email = localStorage.getItem("email");
    const [authorized, setAuthorized] = useState(authorization !== null);
    
    const [showSettingsModal, setSettingsModal] = useState(false);

    const handleShowSettingsModal = () => setSettingsModal(true);

    return (
        <div className="Header">
            {title}                
            {
                authorized &&
                <div>
                    <span className="Header-email">{email} </span>                                     
                    <BsGearFill onClick={handleShowSettingsModal}/>
                    <SettingsModal showModal={showSettingsModal} setShowModal={setSettingsModal} />
                </div>                
            }
        </div>
    )
}