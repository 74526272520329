import PreviousDayoff from "./PreviousDayoff";

export default function Dayoffs({dayoffs}){

    if(!dayoffs || dayoffs.length==0) return;

    return (
        <div className="PreviousDayoffs data-section fade-in-0-5">
            <div className="section-header">No laborables</div>
            <div>
                {
                    dayoffs.map(s => <PreviousDayoff key={s.id} date={s.date} notes={s.notes} />)
                }
            </div>
        </div>
    );
}