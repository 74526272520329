//const HOST = "http://localhost:44528"; //asp.net
//const HOST = "http://localhost:8080"; //go
const HOST = "https://daily-api-upmc3ayfkq-uc.a.run.app"; //google cloud run
//const HOST = "http://my-api.miguelangelcardenas.com";

const LOGIN_URL = HOST + "/v1/user/login"
const TASK_RESPONSE_URL = HOST + "/v1/tasks/response"
const TASK_CREATE_URL = HOST + "/v1/tasks"
const TASK_UPDATE_CONTENT_URL = HOST + "/v1/tasks/content/"
const TASK_UPDATE_STATUS_URL = HOST + "/v1/tasks/status/"
const TASK_DELETE_URL = HOST + "/v1/tasks/"
const TASK_ORDER_URL = HOST + "/v1/tasks/order"
const TASK_DOWNLOAD_URL = HOST + "/v1/tasks/download"
const TASK_MOVE_TO_NEXT_DAY = HOST + "/v1/tasks/to_next_day/"

const DAYOFF_CREATE_URL = HOST + "/v1/dayoffs"
const DAYOFF_DELETE_URL = HOST + "/v1/dayoffs/"
const DAYOFF_GET_ALL_URL = HOST + "/v1/dayoffs/all"

const TOPIC_CREATE_URL = HOST + "/v1/topics"
const TOPIC_UPDATE_URL = HOST + "/v1/topics/"
const TOPIC_DELETE_URL = HOST + "/v1/topics/"
const TOPIC_GET_ALL_URL = HOST + "/v1/topics"

const MESSAGES_GET_FOR_TOPIC_URL = HOST + "/v1/messages/topic/"
const MESSAGES_CREATE_URL = HOST + "/v1/messages"
const MESSAGES_UPDATE_URL = HOST + "/v1/messages/"
const MESSAGES_DELETE_URL = HOST + "/v1/messages/"
const MESSAGES_ORDER_URL = HOST + "/v1/messages/order/"

const SKIP_WEEKENDS = () => {
    return localStorage.getItem("skipWeekends")=="true";
}

export {
    HOST, 
    LOGIN_URL, 
    TASK_RESPONSE_URL,
    TASK_CREATE_URL,
    TASK_UPDATE_CONTENT_URL,
    TASK_UPDATE_STATUS_URL,
    TASK_DELETE_URL,
    TASK_ORDER_URL,
    TASK_DOWNLOAD_URL,
    TASK_MOVE_TO_NEXT_DAY,

    DAYOFF_CREATE_URL,
    DAYOFF_DELETE_URL,
    DAYOFF_GET_ALL_URL,

    TOPIC_CREATE_URL,
    TOPIC_UPDATE_URL,
    TOPIC_DELETE_URL,
    TOPIC_GET_ALL_URL,

    MESSAGES_GET_FOR_TOPIC_URL,
    MESSAGES_CREATE_URL,
    MESSAGES_UPDATE_URL,
    MESSAGES_DELETE_URL,
    MESSAGES_ORDER_URL,

    SKIP_WEEKENDS
};

