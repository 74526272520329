import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { TOPIC_UPDATE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function EditTopicModal({showModal, setShowModal, topic, topicChanged}){

    const [updatingTopic, setUpdatingTopic] = useState(false);    
    const [error, setError] = useState("");
    const [newName, setNewName] = useState(topic.name);

    const handleClose = () => {
        setError("");
        setUpdatingTopic(false);
        setShowModal(false);
        setNewName(topic.name);
    }

    const handleNameChange = (e) =>{
        setNewName(e.target.value);
        setError("");
    }

    const updateTopic = () => {     
        
        if(newName === "" || newName.trim() === ""){
            setError("Debes ingresar un nombre al tema.");
            return;
        }

        setUpdatingTopic(true);
        setError("");

        const url = TOPIC_UPDATE_URL + topic.id;
        const body = {
            "name": newName.trim()
        };
        
        fetch(url, {
            method: "PUT",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
                body: JSON.stringify(body),
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "Unauthorized"};
            if(response.status===404) return response.json();
            return Promise.reject(response);
        }).then(json => {            
            if(json?.error){
                setError(json.error);
                return;
            }               
            const updatedTopic = {
                id: topic.id,
                name: newName.trim()
            };
            topicChanged(updatedTopic);
            setError("");
            setUpdatingTopic(false);
            setShowModal(false);        
        }).catch((response) =>{
            console.log("catch");
            console.log(response);
            setError("Ocurrió un error");
        }).finally(() =>{
            setUpdatingTopic(false);
        });  
    }

    const isInvalid = error !== "";

    if(topic == null){
        return null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Control type="text" isInvalid={isInvalid} value={newName} onChange={handleNameChange}/>                
                {
                    error &&
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    updatingTopic
                    ? <Button className="w-spinner" variant="primary" disabled>Guardando <Spinner /></Button>
                    : 
                    <>
                        <Button variant="primary" onClick={updateTopic}>Guardar</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}