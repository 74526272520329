import { BsCheckSquareFill, BsSquare} from "react-icons/bs";

export default function CheckableSquare({done, onCheck, onUncheck}){
    return (
        <div className="icon-container">
            {
                done
                ? <BsCheckSquareFill className="cur-def" onClick={onUncheck}/>
                : <BsSquare className="cur-def" onClick={onCheck}/>
            }
        </div>
    );
}