import { useState } from "react";
import { DAYOFF_CREATE_URL } from "../../utils/config";
import Spinner from "../Spinner";

export default function NewDayoff({parentLoadDayoffs}){

    const [date, setDate] = useState('');
    const [notes, setNotes] = useState('');
    const [general, setGeneral] = useState(true);
    const [loading, setLoading] = useState(false);
    const [validationError, setValidationError] = useState('');

    const save = (e) =>{
        e.preventDefault();
        setLoading(true);
        setValidationError('');

        const dayoff = {
            date: date,
            notes: notes,
            general: general
        };

        fetch(DAYOFF_CREATE_URL,{
            method: "POST",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
            body: JSON.stringify(dayoff)
        })
        .then(response =>{
            if(response.ok){
                return response.json()
            }
            return Promise.reject(response);
        })
        .then(json =>{
            setDate('');
            setNotes('');
            parentLoadDayoffs();
        })
        .catch(response =>{
            response.json()
            .then(json =>{
                //TODO: hacer algo con la validación del error...
                if(json.error){
                    setValidationError(json.error);
                }else{
                    setValidationError(JSON.stringify(json));
                }
                console.log(json);
            });
        })
        .finally(() =>{
            setLoading(false);
        });
    }

    return (
        <div className="NewDayoff data-section">
            <div className="section-header">{loading ? "Guardando": "Nuevo día"}</div>
            {
                loading
                ?
                <div className="saving-container">
                    <Spinner />
                </div>
                :
                <form onSubmit={save}>
                    {
                        validationError &&
                        <div className="text-danger">
                            {validationError}
                        </div>
                    }
                    <div className="form-floating mb-3">
                        <input required className="form-control" id="floatingInput" placeholder="dd-MM-yyyy" value={date} onChange={e => setDate(e.target.value)} />
                        <label htmlFor="floatingInput">Fecha (dd-MM-yyyy)</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input required className="form-control" id="floatingInput" placeholder="dd-MM-yyyy" value={notes} onChange={e => setNotes(e.target.value)} />
                        <label htmlFor="floatingInput">Notas</label>
                    </div>
                    <div className="general-container">
                        <label htmlFor="inputGeneral" className="form-label">General</label>
                        <input id="inputGeneral" checked={general} onChange={(e) => setGeneral(e.target.checked)} className="form-input" type="checkbox" />
                    </div>
                    <button className="btn btn-dark">Guardar</button>
                </form>
            }
        </div>        
    );
}