import { getDateFromDDMMYYYY, getDayName, isYesterday } from "../../utils/dateHelper";
import PreviousTask from "./PreviousTask";

export default function PreviousTasks({dateString, tasks}){

    const title = "Lo que hice" + getTitle(dateString);

    return (
        <div className="PreviousTasks data-section fade-in-0-5">
            <div className="section-header">{title}</div>
            {
                tasks &&
                (
                    tasks.length > 0 
                    ? tasks.map(s => <PreviousTask key={s.id} content={s.content} /> )
                    : <span className="empty-activities">Sin actividades.</span>
                )                    
            }                
        </div>
    );
}

const getTitle=(dateString) =>{
    if(!dateString) return "...";
    const date = getDateFromDDMMYYYY(dateString);
    const prefix = isYesterday(date) ? "ayer" : "el"
    return " " + prefix + " " + getDayName(date) + " " + dateString;
}