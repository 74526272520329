import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { MESSAGES_CREATE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function NewMessage({topicId, addMessage}){

    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [creating, setCreating] = useState(false);

    const handleMessageChange = (e) =>{
        setMessage(e.target.value);
        setIsInvalid(false);
    };

    const handleSubmit = (e) =>{
        setIsInvalid(false);
        e.preventDefault();        
        if(message === "" || message.trim() === ""){
            setIsInvalid(true);
            setError("Debes ingresar contenido al mensaje.");
            return;
        }

        setCreating(true);

        setError("");

        const newMessage = {
            content: message.trim(),
            topic_id: topicId
        }

        fetch(MESSAGES_CREATE_URL, {
            method: "POST",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
                body: JSON.stringify(newMessage),
        }).then(response => {
            if(response.status===201) return response.json();
            if(response.status===400) return {error: "bad request"};
            return Promise.reject(response);
        }).then(json => {
            if(json.error){
                setIsInvalid(true);
                setError(json.error);
                return;
            }
            addMessage(json);
            setMessage("");
        }).catch(response =>{
            console.log("catch");
            setError(response);
        }).finally(() =>{
            setCreating(false);
        })
    };
    
    return (
        <div className="new-message">
            <Form onSubmit={handleSubmit}>
                {
                    creating
                    ? <>
                        <span className="creating-content">{message}</span>                        
                        <Spinner />
                    </>
                    : <>
                        <div className="width-100">
                            <Form.Control type="text" isInvalid={isInvalid} value={message} onChange={handleMessageChange} />
                            {
                                error &&
                                <Form.Control.Feedback type="invalid">
                                    {error}
                                </Form.Control.Feedback>
                            }
                        </div>
                        <Button type="submit" variant="primary">Agregar</Button>
                    </>
                }
                
            </Form>
        </div>
    );
}