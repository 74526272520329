import { useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MESSAGES_GET_FOR_TOPIC_URL, MESSAGES_ORDER_URL } from "../../../utils/config";
import Spinner from "../../Spinner";
import DeleteMessageModal from "./DeleteMessageModal";
import Message from "./Message";
import NewMessage from "./NewMessage";

export default function MessagesContainer({topicId, topicName}){

    const [loadingMessages, setLoadingMessages] = useState(false);

    const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
    const handleShowDeleteTopicModal = () => setShowDeleteMessageModal(true);
    const [messageToDelete, setMessageToDelete] = useState();

    const handleDeleteRequest = (message) => {
        setMessageToDelete(message);
        handleShowDeleteTopicModal();
    }

    const [messages, setMessages] = useState([]);

    const removeMessage = (message) =>{
        setMessages(messages.filter(m => m.id !== message.id));
    }
    
    const addMessage = (message) =>{
        setMessages(oldArray => [...oldArray, message]);
    }

    useEffect(() =>{
        loadMessages();
    }, [topicId]);
    
    const loadMessages = () =>{
        if(topicId === 0) return;
        setLoadingMessages(true);
        const url = MESSAGES_GET_FOR_TOPIC_URL + topicId;
        fetch(url, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "unauthorized"};
            return Promise.reject(response);
        }).then(json =>{
            if(json.error){
                console.error(json.error);
                return;
            }
            setMessages(json);
        }).catch(response =>{
            console.log("catch");
            console.log(response);
        }).finally(() =>{
            setLoadingMessages(false);
        })
    };

    const handleDrop = (droppedItem) => {
        if(!droppedItem.destination) return;
        var updatedList = [...messages];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setMessages(updatedList);       
        updateOrder(updatedList.map(s => s.id));        
    };
    
    const updateOrder = (ids) => {
        const url =MESSAGES_ORDER_URL + topicId;
        const body = {
            order: ids
        }
        fetch(url, {
            method: "POST",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
                body: JSON.stringify(body),
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===400) return {error: "bad request"};
            if(response.status===401) return {error: "unauthorized"}; 
            return Promise.reject(response);
        }).then(json => {
            if(json.error){
                console.error(json.error);
                return;
            }
        }).catch(response => {
            console.log("catch");
            console.log(response);
        }).finally(() =>{

        });
    };

    if(loadingMessages) return (
        <div className="big-spinner fade-in-1">
            <Spinner />
            <div className="spinner-text">Cargando mensajes...</div>
        </div>
    );

    if(topicId===0){
        return null;
    }
    
    return (
        <div className="messages-container fade-in-1">
            <div className="topic-name">{topicName}</div>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div className="list-container" {...provided.droppableProps} ref={provided.innerRef} >
                        {
                            messages.map((message, index) => (
                                <Draggable isDragDisabled={false} key={message.id} draggableId={message.id + ""} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                            <Message className="message-card" message={message} deleteRequest={handleDeleteRequest}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))
                        }
                        {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <NewMessage topicId={topicId} addMessage={addMessage} />
            <DeleteMessageModal showModal={showDeleteMessageModal} setShowModal={setShowDeleteMessageModal} removeMessage={removeMessage} message={messageToDelete} />    
        </div>
    );
}