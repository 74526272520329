import "./daysManager.css";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import NotAuthenticated from "../components/NotAuthenticated";
import DaysManager from "../components/daysManager/DaysManager";

export default function DaysManagerPage(){

    const [title, setTitle] = useOutletContext();
    useEffect(() => setTitle("No laborables"),[]);

    const authorized = localStorage.getItem("authorization") !== null;
  
    return (
        <>
            {
                authorized
                ? <DaysManager />
                : <NotAuthenticated />
            }
        </>    
    );
}