import "./login.css";
import { useState } from 'react';
import { LOGIN_URL } from '../utils/config';
import Spinner from '../components/Spinner';
import { BsFillPenFill } from 'react-icons/bs';
import Footer from "../components/Footer";

export default function Login(){

    const [email, setEmail] = useState(localStorage.getItem('email') ?? '');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [passError, setPassError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);

    const loginEnabled = email !== "" && password !== "";

    const login = (e) =>{
        e.preventDefault();

        setError('');
        setPassError('');
        setEmailError('');

        localStorage.removeItem("authorization");

        const credentials = {
            "email": email,
            "password": password
        };
        setLoading(true);

        fetch(LOGIN_URL,{
            method: "POST",
            headers: {"Content-type": "application/json;charset=UTF-8"},
            body: JSON.stringify(credentials)
        })
        .then(response => {
            if(response.ok){
                return response.json()
            }            
            return Promise.reject(response);
        })
        .then(json =>{
            if(json.authorization){
                localStorage.setItem("email", email);
                localStorage.setItem("authorization", json.authorization);
                window.location.href="/";
            }else{
                setError("Achis achis");
            }
        })
        .catch((response,e) =>{
            response.json()
            .then(json =>{
                if(json.message){
                    setError(json.message);
                }else{
                    if(json.Password){
                        setPassError(json.Password);
                    }
                    if(json.Email){
                        setEmailError(json.Email);
                    }
                }
            });
        }).finally(() =>{
            setLoading(false);
        });
    }
    return (
        <>
            <div className="Login">
                <div className="login-card">
                    <BsFillPenFill color='#646464' size={"5rem"} />
                    <form onSubmit={login}>
                        {
                            error && <label className='text-danger'>{error}</label>
                        }                

                        {
                            emailError && <span className="text-danger">{emailError}</span>
                        }

                        <input required type="email" className="form-control" placeholder="Correo" value={email} onChange={e => setEmail(e.target.value)} />
                        <br />

                        {
                            passError && <span className="text-danger">{passError}</span>
                        }
                        <input required type="password" className="form-control" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} />

                        <br />
                        <div>
                            {
                                loading
                                ? <button className='btn btn-secondary full-width' disabled><span style={{marginRight:"8px"}}>Validando</span><Spinner /></button>
                                : <button style={{width:"100%"}} className='btn btn-dark' disabled={!loginEnabled} >Entrar</button>
                            }                    
                        </div>

                    </form>

                </div>
                <Footer />
            </div>
        </>
    );
}