import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { TOPIC_CREATE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function CreateTopicModal({showModal, setShowModal, addTopic}){

    const [creatingTopic, setCreatingTopic] = useState(false);
    const [topicName, setTopicName] = useState("");
    const [error, setError] = useState("");

    const handleClose = () => {
        setTopicName("");
        setError("");
        setCreatingTopic(false);
        setShowModal(false);
    }

    const createTopicInternal = () => {        
        setCreatingTopic(true);
        setError("");

        //const url = HOST + "/api/topic";
        const newTopic = {
            name: topicName 
        };
        
        fetch(TOPIC_CREATE_URL, {
            method: "POST",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
                body: JSON.stringify(newTopic),
        })
        .then(response => {
            if(response.ok) return response.json();
            if(response.status===400) return {error: "bad request"};
            if(response.status===401) return {error: "unauthorized"};
            return Promise.reject(response);
        }).then(json => {  
            if(json.error){
                setError(json.error);
                return;
            }
            const topic = {
                id: json.id,
                name: json.name,
            }    
            addTopic(topic);
            handleClose();
        })
        .catch((response) =>{
            console.log("catch");
            console.log(response);
            setError("Ocurrió un error");
        }).finally(() =>{
            setCreatingTopic(false);
        });  
    }

    const isInvalid = error !== "";

    const handleTopicNameChange = (e) =>{
        setTopicName(e.target.value);
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Nuevo tema</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" isInvalid={isInvalid} value={topicName} onChange={handleTopicNameChange}/>                
                {
                    error &&
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    creatingTopic
                    ? <Button className="w-spinner" variant="primary" disabled>Guardando <Spinner /></Button>
                    : 
                    <>
                        <Button variant="primary" onClick={createTopicInternal}>Guardar</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}