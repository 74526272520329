import { useState, useEffect } from "react";
import { formatDate } from "../../../utils/formats";
import Activity from "./Activity";
import NewActivity from "./NewActivity";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TASK_ORDER_URL } from "../../../utils/config";
import DeleteActivityModal from "./DeleteActivityModal";
import MoveActivityModal from "./MoveActivityModal";
import {BsListUl} from "react-icons/bs";

export default function ActivitiesContainer({date, activities, setActivities, onClipboardRequest}){

    const addActivity = (activity) =>{
        setActivities(oldArray => [...oldArray, activity]);
    };

    const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false);
    const handleShowDeleteTopicModal = () => setShowDeleteActivityModal(true);
    const [activityToDelete, setActivityToDelete] = useState();

    const [showMoveActivityModal, setShowMoveActivityModal] = useState(false);
    const handleShowMoveModal = () => setShowMoveActivityModal(true);
    const [activityToMove, setActivityToMove] = useState();

    const handleDeleteRequest = (activity) => {
        setActivityToDelete(activity);
        handleShowDeleteTopicModal();
    }
    
    const handleMoveRequest = (activity) => {
        setActivityToMove(activity);
        handleShowMoveModal();
    }

    const handleDrop = (droppedItem) => {
        if(!droppedItem.destination) return;
        var updatedList = [...activities];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        setActivities(updatedList);       
        updateOrder(updatedList.map(s => s.id));
    };

    const removeActivity = (activity) =>{
        setActivities(activities.filter(a => a.id !== activity.id));
    }
    
    const updateOrder = (ids) => {
        const body = {
            date: formatDate(date),
            ids: ids
        }
        fetch(TASK_ORDER_URL, {
            method: "PUT",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
                body: JSON.stringify(body),
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===400) return {error: "bad request"};
            if(response.status===401) return {error: "unauthorized"}; 
            return Promise.reject(response);
        }).then(json => {
            if(json.error){
                console.error(json.error);
                return;
            }
        }).catch(response => {
            console.log("catch");
            console.log(response);
        }).finally(() =>{

        });
    };

    return (
        <div className="data-section fade-in-0-5">
            <div className="section-header">Actividades <BsListUl cursor={"Pointer"} title="Copiar al clipboard" onClick={onClipboardRequest}/></div>
            <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="list-container">
                    {(provided) => (
                        <div className="list-container" {...provided.droppableProps} ref={provided.innerRef} >
                        {
                            activities.map((activity, index) => (
                                <Draggable isDragDisabled={false} key={activity.id} draggableId={activity.id + ""} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                            <Activity className="activity-card" activity={activity} deleteRequest={handleDeleteRequest} moveRequest={handleMoveRequest}/>
                                        </div>
                                    )}
                                </Draggable>
                            ))
                        }
                        {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <NewActivity date={date} addActivity={addActivity} />
            <DeleteActivityModal showModal={showDeleteActivityModal} setShowModal={setShowDeleteActivityModal} removeActivity={removeActivity} activity={activityToDelete} />
            <MoveActivityModal showModal={showMoveActivityModal} setShowModal={setShowMoveActivityModal} removeActivity={removeActivity} activity={activityToMove} />
        </div>
    );
}