import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TASK_DELETE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function DeleteActivityModal({showModal, setShowModal, removeActivity, activity}){
    const [deletingActivity, setDeletingActivity] = useState(false);    
    const [error, setError] = useState("");

    const handleClose = () => {
        setError("");
        setDeletingActivity(false);
        setShowModal(false);
    }

    const deleteActivity = () => {        
        setDeletingActivity(true);
        setError("");

        const url = TASK_DELETE_URL + activity.id;
        
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
        })
        .then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "Unauthorized"};
            if(response.status===404) return response.json();
            return Promise.reject(response);
        }).then(json => {           
            if(json?.error){
                setError(json.error);
                return;
            }   
            removeActivity(activity);
            handleClose();
        })
        .catch((response) =>{
            console.log("catch");
            console.log(response);
            setError("Ocurrió un error");
        }).finally(() =>{
            setDeletingActivity(false);
        });  
    }

    if(activity == null){
        return null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>¿Seguro que quieres eliminar la actividad <strong>{activity.content}</strong>?</p>
                {
                    error && <div className="text-danger">{error}</div>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    deletingActivity
                    ? <Button className="w-spinner" variant="danger" disabled>Eliminando <Spinner /></Button>
                    : 
                    <>
                        <Button variant="danger" onClick={deleteActivity}>Eliminar</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}