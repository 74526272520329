import { SKIP_WEEKENDS } from "./config";

function getNextDay(date){
    let result = addDays(date, 1);
    if(SKIP_WEEKENDS()){
        while(isWeekend(result)){
            result = addDays(result, 1);
        }
    }
    return result;
}

function getPreviousDay(date){
    let result = addDays(date, -1);
    if(SKIP_WEEKENDS()){
        while(isWeekend(result)){
            result = addDays(result, -1);
        }
    }
    return result;
}

function isWeekend(date){
    return date.getDay()==0 || date.getDay()==6;
}

function addDays(date, days){
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function getDateFromDDMMYYYY(date){
    const parts = date.split("-");
    return new Date(parts[2], parts[1]-1, parts[0]);
}

function isToday(date){
    const today = new Date();
    return date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear();
}

function isYesterday(date){
    const today = addDays(date, 1);
    return isToday(today);
}

function getDayName(date){
    switch(date.getDay()){
        case 0:
            return "domingo";
        case 1:
            return "lunes";
        case 2:
            return "martes";
        case 3:
            return "miércoles";
        case 4:
            return "jueves";
        case 5:
            return "viernes";
        case 6:
            return "sábado";
        default:
            return "?";
    }
}

export {
    addDays, 
    isToday, 
    isYesterday, 
    getDayName, 
    getDateFromDDMMYYYY, 
    getNextDay, 
    getPreviousDay
}