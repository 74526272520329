import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import MainContainer from "../../components/daily/MainContainer";
import NotAuthenticated from "../../components/NotAuthenticated";
import "./style.css";

export default function DailyPage(){

    const [_, setTitle] = useOutletContext();
    useEffect(() => setTitle("Daily"), []);

    const authorized = localStorage.getItem("authorization") !== null;

    return (
        <>
            {
                authorized
                ? <MainContainer />
                : <NotAuthenticated />
            }
        </>
    );
}