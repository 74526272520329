import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MESSAGES_DELETE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function DeleteMessageModal({showModal, setShowModal, removeMessage, message}){
    const [deletingMessage, setDeletingMessage] = useState(false);    
    const [error, setError] = useState("");

    const handleClose = () => {
        setError("");
        setDeletingMessage(false);
        setShowModal(false);
    }

    const deleteMessage = () => {        
        setDeletingMessage(true);
        setError("");

        const url =MESSAGES_DELETE_URL + message.id;
        
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
        })
        .then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "Unauthorized"};
            if(response.status===404) return response.json();
            return Promise.reject(response);
        }).then(json => {            
            if(json?.error){
                setError(json.error);
                return;
            }   
            removeMessage(message);
            handleClose();
        })
        .catch((response) =>{
            console.log("catch");
            console.log(response);
            setError("Ocurrió un error");
        }).finally(() =>{
            setDeletingMessage(false);
        });  
    }

    if(message == null){
        return null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>¿Seguro que quieres eliminar el mensaje <strong>{message.content}</strong>?</p>
                {
                    error && <div className="text-danger">{error}</div>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    deletingMessage
                    ? <Button className="w-spinner" variant="danger" disabled>Eliminando <Spinner /></Button>
                    : 
                    <>
                        <Button variant="danger" onClick={deleteMessage}>Eliminar</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}