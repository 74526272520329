import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function SettingsModal({showModal, setShowModal}) {

    const [skipWeekends, setSkipWeekends] = useState(localStorage.getItem("skipWeekends")=="true");
    
    const handleClose = () => {
        setShowModal(false);
    }

    const handleChange = (e) => {
        localStorage.setItem("skipWeekends", e.target.checked);
        setSkipWeekends(e.target.checked);
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Configuración</Modal.Title>
            </Modal.Header>

            <Modal.Body>                            
                Saltar fines de semana <input type="checkbox" defaultChecked={skipWeekends} onChange={handleChange} />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>                
            </Modal.Footer>
        </Modal>
    );

}