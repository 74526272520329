import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import DailyPage from './routes/daily/page';
import DaysManagerPage from './routes/daysManagerPage';
import Login from './routes/login';
import Header from './components/Header';
import Nav from './components/Nav';
import MessagesPage from './routes/messages/page';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

//https://contactmentor.com/react-drag-drop-list/

root.render(
  // <React.StrictMode>
  // </React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='/' element={<DailyPage />} />
          <Route path='/daysManager' element={<DaysManagerPage />} />
          <Route path='/messages' element={<MessagesPage />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </BrowserRouter>
);

function App(){

  const [title, setTitle] = useState('App');

  return (
    <div className='App'>
      <Nav />
      <div className='page-container'>
        <Header title={title} />
        <div className='page-content'>
          <Outlet context={[title, setTitle]} />      
        </div>
      </div>
    </div>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();