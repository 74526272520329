import { useState } from "react";
import { useEffect } from "react";
import { TOPIC_GET_ALL_URL } from "../../../utils/config";
import Spinner from "../../Spinner";
import DeleteTopicModal from "./DeleteTopicModal";
import Topic from "./Topic";
import NewTopic from "./NewTopic";

export default function TopicsContainer({setTopicId, setTopicName, notifyTopicRemoved, notifyTopicNameChanged}){

    const [topics, setTopics] = useState([]);
    const [loadingTopics, setLoadingTopics] = useState(false);

    const [showDeleteTopicModal, setShowDeleteTopicModal] = useState(false);
    const handleShowDeleteTopicModal = () => setShowDeleteTopicModal(true);
    const [topicToDelete, setTopicToDelete] = useState();

    const handleDeleteRequest = (topic) => {
        setTopicToDelete(topic);    
        handleShowDeleteTopicModal();
    };

    const removeTopic = (topic) =>{        
        setTopics(topics.filter(t => t.id !== topic.id));     
        notifyTopicRemoved(topic.id);
    };

    const addTopic = (topic) =>{
        setTopics(oldArray => [...oldArray, topic]);
        handleTopicSelected(topic);
    };

    const handleTopicSelected = (topic) =>{     
        setTopicId(topic.id);
        setTopicName(topic.name);
    };
    
    const handleTopicChanged = (topic) =>{
        notifyTopicNameChanged(topic.id, topic.name);
    };

    useEffect(() =>{
        loadTopics();
    }, []);

    const loadTopics = () =>{
        setLoadingTopics(true);

        //const url = HOST + "/api/topic";
        fetch(TOPIC_GET_ALL_URL, {
            method: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "unauthorized"};
            return Promise.reject(response);
        }).then(json =>{
            if(json.error){
                console.error(json.error);
                return;
            }
            setTopics(json);
            if(json.length>0){
                setTopicId(json[0].id);
                setTopicName(json[0].name);
            }
        }).catch(response =>{
            console.log("catch");
            console.log(response);
        }).finally(() =>{
            setLoadingTopics(false);
        });
    };   

    if(loadingTopics) return (
        <>
            <div className="mid-spinner fade-in-1">
                <Spinner />
                <div className="spinner-text">Cargando temas...</div>
            </div>
        </>
    );

    return (
        <>
            <NewTopic addTopic={addTopic} />
            <DeleteTopicModal showModal={showDeleteTopicModal} setShowModal={setShowDeleteTopicModal} removeTopic={removeTopic} topic={topicToDelete} />
            {
                topics.map(topic => <Topic onClick={handleTopicSelected} key={topic.id} aTopic={topic} deleteRequest={handleDeleteRequest} notifyTopicChanged={handleTopicChanged} />)                
            }
        </>
    );
}