import { BsTrash } from "react-icons/bs";

export default function Dayoff({dayoff, deleteRequest}){
    
    const handleDeleteRequest = () => {
        deleteRequest(dayoff);
    }

    return (
        <div className="Dayoff">
            <div className="header">
                <span className="date">{dayoff.date}</span>  
                <button className="btn icon-btn" type="button" >
                    <BsTrash onClick={handleDeleteRequest} />                                    
                </button>            
            </div>
            <div>{dayoff.general ? "" : "[Personal]"} {dayoff.notes}</div>
        </div>   
    );
}