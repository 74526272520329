import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TOPIC_DELETE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function DeleteTopicModal({showModal, setShowModal, removeTopic, topic}){

    const [deletingTopic, setDeletingTopic] = useState(false);    
    const [error, setError] = useState("");

    const handleClose = () => {
        setError("");
        setDeletingTopic(false);
        setShowModal(false);
    }

    const deleteTopic = () => {        
        setDeletingTopic(true);
        setError("");

        const url = TOPIC_DELETE_URL + topic.id;
        
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "Unauthorized"};
            if(response.status===404) return response.json();
            return Promise.reject(response);
        }).then(json => {            
            if(json?.error){
                setError(json.error);
                return;
            }   
            removeTopic(topic);
            handleClose();
        }).catch((response) =>{
            console.log("catch");
            console.log(response);
            setError("Ocurrió un error");
        }).finally(() =>{
            setDeletingTopic(false);
        });  
    }

    if(topic == null){
        return null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>¿Seguro que quieres eliminar el tema <strong>{topic.name}</strong>?</p>
                {
                    error && <div className="text-danger">{error}</div>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    deletingTopic
                    ? <Button className="w-spinner" variant="danger" disabled>Eliminando <Spinner /></Button>
                    : 
                    <>
                        <Button variant="danger" onClick={deleteTopic}>Eliminar</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}