import { useState } from "react";
import { Button } from "react-bootstrap";
import CreateTopicModal from "./CreateTopicModal";

export default function NewTopic({addTopic}){

    const [showNewTopicModal, setShowNewTopicModal] = useState(false);
    const handleShowNewTopicModal = () => setShowNewTopicModal(true);

    return (
        <div className="NewTopic">
            <Button variant="primary" onClick={handleShowNewTopicModal}>Nuevo tema</Button>
            <CreateTopicModal showModal={showNewTopicModal} setShowModal={setShowNewTopicModal} addTopic={addTopic} />
        </div>
    );
}