import { BsEmojiDizzy } from "react-icons/bs";

export default function Error({message}){
    return (
        <div className="custom-error">
            <BsEmojiDizzy />
            <div>{message}</div>            
        </div>
    )
}
