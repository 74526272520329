function formatDate(date){
    const d = new Date(date);
    const month = '' + (d.getMonth() +1);
    const day = '' + d.getDate();
    const year = d.getFullYear();
    const monthString = month.length < 2 ? '0' + month : month;
    const dayString = day.length < 2 ? '0' + day : day;
    return dayString + '-' + monthString + '-' + year;
}

export {formatDate}