import { useState } from "react";
import { BsPencilFill, BsTrash } from "react-icons/bs";
import EditTopicModal from "./EditTopicModal";

export default function Topic({aTopic, deleteRequest, onClick, notifyTopicChanged}){

    const [topic, setTopic] = useState(aTopic);

    const [showEditTopicModal, setShowEditTopicModal] = useState(false);
    const handleShowEditTopicModal = () => setShowEditTopicModal(true);

    const handleDelete = () =>{
        deleteRequest(topic);
    }

    const handleClick = () =>{
        onClick(topic);
    }

    const handleChangeNameRequest = () =>{
        handleShowEditTopicModal();
    }

    const handleTopicChanged = (newTopic) =>{
        setTopic(newTopic);
        notifyTopicChanged(newTopic);
    };

    return (
        <div className="Topic">
            <EditTopicModal showModal={showEditTopicModal} setShowModal={setShowEditTopicModal} topic={topic} topicChanged={handleTopicChanged} /> 
            <div className="topic-name" onClick={handleClick}>{topic.name}</div>
            <div className="topic-controls">
                <div><BsPencilFill className="icon" title="editar tema" onClick={handleChangeNameRequest}/></div>
                <div><BsTrash className="icon" title="eliminar tema" onClick={handleDelete}/></div>        
            </div>
        </div>
    );
}