import { useState } from "react";
import {BsCheckLg, BsPencilFill, BsTrash, BsXLg} from "react-icons/bs";
import { Form } from "react-bootstrap";
import { MESSAGES_UPDATE_URL } from "../../../utils/config";
import Spinner from "../../Spinner";

export default function Message({message, deleteRequest}){

    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(message.content)
    const [previousValue, setPreviousValue] = useState(message.content);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);

    const handleEditClick = () =>{
        setEditing(true);
    };

    const handleDeleteRequest = () => {
        deleteRequest(message);
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        handleUpdateValue();
    }

    const handleMessageChange = (e) => {
        setValue(e.target.value);
        setIsInvalid(false);
    }

    const handleUpdateValue = () => {
        
        if(value === "" || value.trim() === ""){
            setIsInvalid(true);
            setError("Debes ingresar contenido al mensaje.");
            return;
        }

        setUpdating(true);

        const url = MESSAGES_UPDATE_URL + message.id;
        const body = {
            content: value
        };
        fetch(url, {
            method: "PUT",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
                body: JSON.stringify(body),
        }).then(response => {
            if(response.ok) return response.json();
            if(response.status===400) return {error: "bad request"};
            if(response.status===401) return {error: "unauthorized"};
            return Promise.reject(response);
        }).then(json =>{
            if(json?.error){
                console.error(json.error);
                return;
            }
            setPreviousValue(value);
            setEditing(false);
        }).catch(response =>{
            console.log("catch");
            console.log(response);
        }).finally(() => {
            setUpdating(false);
        });
    }

    const handleCancelEdition = () => {
        setValue(previousValue);
        setEditing(false);
        setError("");
        setIsInvalid(false);
    }

    return (
        <div className="message-card">
            {
                editing
                ?<>
                    {
                        updating
                        ?<>
                           <span className="content">{value}</span>
                           <Spinner /> 
                        </>
                        :<Form onSubmit={handleSubmit}>
                            <div className="width-100">
                                <Form.Control isInvalid={isInvalid} value={value} onChange={handleMessageChange} />
                                {
                                    error &&
                                    <Form.Control.Feedback type="invalid">
                                        {error}
                                    </Form.Control.Feedback>
                                }
                            </div>
                            <div className="buttons">
                                <button type="button" className="btn icon-btn green" onClick={handleUpdateValue}><BsCheckLg /></button>
                                <button type="button" className="btn icon-btn red" onClick={handleCancelEdition}><BsXLg /></button>
                            </div>
                        </Form>
                    }
                </>
                :<>
                    <span className="content" onDoubleClick={handleEditClick}>{value}</span>
                    <div className="buttons">
                        <button className="btn icon-btn" onClick={handleEditClick}><BsPencilFill /></button>
                        <button className="btn icon-btn" onClick={handleDeleteRequest}><BsTrash /></button>
                    </div>
                </> 
            }
        </div>
    );
}