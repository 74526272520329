import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { DAYOFF_DELETE_URL } from "../../utils/config";
import Spinner from "../Spinner";

export default function DeleteDayoffModal({showModal, setShowModal, removeDayoff, dayoff}){
    const [deletingDayoff, setDeletingDayoff] = useState(false);    
    const [error, setError] = useState("");

    const handleClose = () => {
        setError("");
        setDeletingDayoff(false);
        setShowModal(false);
    }

    const deleteDayoff = () => {        
        setDeletingDayoff(true);
        setError("");

        const url = DAYOFF_DELETE_URL + dayoff.id;        
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage.getItem("authorization")
            },
        })
        .then(response => {
            if(response.ok) return response.json();
            if(response.status===401) return {error: "Unauthorized"};
            if(response.status===404) return response.json();
            return Promise.reject(response);
        }).then(json => {
            if(json?.error){
                setError(json.error);
                return;
            }   
            removeDayoff(dayoff);
            handleClose();
        }).catch((response) =>{
            console.log("catch");
            console.log(response);
            setError("Ocurrió un error");
        }).finally(() =>{
            setDeletingDayoff(false);
        });  
    }

    if(dayoff == null){
        return null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>¿Seguro que quieres eliminar el día <strong>{dayoff.date} {dayoff.notes}</strong>?</p>
                {
                    error && <div className="text-danger">{error}</div>
                }
            </Modal.Body>

            <Modal.Footer>
                {
                    deletingDayoff
                    ? <Button className="w-spinner" variant="danger" disabled>Eliminando <Spinner /></Button>
                    : 
                    <>
                        <Button variant="danger" onClick={deleteDayoff}>Eliminar</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}