import { useEffect, useState } from "react";
import { DAYOFF_GET_ALL_URL } from "../../utils/config";
import Dayoff from "./Dayoff";
import Error from "../Error";
import Spinner from "../Spinner";
import NewDayoff from "./NewDayoff";
import DeleteDayoffModal from "./DeleteDayoffModal";

export default function Dayoffs(){
    
    const [dayoffs, setDayoffs] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const [error, setError] = useState();

    const [showDeleteDayoffModal, setShowDeleteDayoffModal] = useState(false);
    const handleShowDeleteDayoffModal = () => setShowDeleteDayoffModal(true);
    const [dayoffToDelete, setDayoffToDelete] = useState();
    
    const handleDeleteRequest = (dayoff) =>{
        setDayoffToDelete(dayoff);
        handleShowDeleteDayoffModal();
    }

    useEffect(() =>{
        loadDayoffs();
    }, []);

    const removeDayoff = (dayoff) => {
        setDayoffs(dayoffs.filter(d => d.id !== dayoff.id));        
    }

    // const addDayoff = (dayoff) =>{
    //     setDayoffs(oldArray => [...oldArray, dayoff]);
    // }

    const loadDayoffs = () =>{
        setLoading(true);

        //const url = HOST + "/api/dayoff";
        fetch(DAYOFF_GET_ALL_URL,{
            methos: "GET",
            headers: {
                "Content-type": "application/json;charset=UTF-8",
                //'Authorization': localStorage. getItem("authorization")
            }
        }).then(response =>{
            if(response.ok) return response.json();
            if(response.status===401) return {error: "unauthorized"};
            return Promise.reject(response);
        })
        .then(json =>{
            if(json.error){
                setError(json.error);
                return;
            }
            setDayoffs(json);
        }).catch(response =>{
            console.log("catch");
            console.log(response);
        }).finally(()=>{
            setLoading(false);
        });
    }

    if(error){
        return (
            <Error message={error.message} />
        )
    }

    if(loading){
        return (
            <div className="mid-spinner fade-in-1">
                <Spinner />
            <div className="spinner-text">Cargando días...</div>
        </div>
        );
    }

    return (
        <div className="Dayoffs">            
            <NewDayoff parentLoadDayoffs={loadDayoffs}/>
            <DeleteDayoffModal showModal={showDeleteDayoffModal} setShowModal={setShowDeleteDayoffModal} removeDayoff={removeDayoff} dayoff={dayoffToDelete} />
            <div className="data-section">
                <div className="section-header">Días registrados</div>
                {
                    dayoffs.map(dayoff => <Dayoff key={dayoff.id} dayoff={dayoff} deleteRequest={handleDeleteRequest} />)
                }
            </div>
        </div>
    );
}