import { BiCalendar, BiLogOut, BiPencil, BiMessageDetail} from "react-icons/bi";
import { Link } from "react-router-dom";

export default function Nav(){

    const logout = () =>{
        localStorage.removeItem("authorization");
    }

    return (
        <nav>
            <div>
                <div>
                    <Link title="Daily" to="/"><BiPencil fontSize="2rem"/></Link>
                </div>
                <div>
                    <Link title="Días no laborables" to="/daysManager"><BiCalendar fontSize="2rem"/></Link>
                </div>
                <div>
                    <Link title="Mensajes" to="/messages"><BiMessageDetail fontSize="2rem"/></Link>
                </div>
            </div>
            <div>
                <Link onClick={logout} title="Salir" to="/login"><BiLogOut fontSize="2rem"/></Link>
            </div>
        </nav>
    );
}