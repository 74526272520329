import { useState, useEffect } from "react";
import { TASK_RESPONSE_URL } from "../../utils/config";
import { formatDate } from "../../utils/formats";
import DayHeader from "./DayHeader";import ActivitiesContainer from "./activities/ActivitiesContainer";
import Spinner from "../Spinner";
import DayoffDetails from "./DayoffDetails";
import Dayoffs from "./Dayoffs";
import PreviousTasks from "./PreviousTasks";
import { getDateFromDDMMYYYY, getDayName, isYesterday } from "../../utils/dateHelper";
import { Toast, ToastContainer } from "react-bootstrap";

export default function MainContainer(){

    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [tasks, setTasks] = useState();

    const [showToast, setShowToast] = useState(false);

    useEffect(() =>{
        loadData();
    }, [date])

    const loadData = () =>{

        setData(null);
        setTasks(null);
        setLoading(true);

        const url = TASK_RESPONSE_URL + "/" + formatDate(date);
        fetch(url,{
            method: "GET",
            //headers: {'Authorization': localStorage.getItem("authorization")}
        })
        .then(response =>{
            if(response.ok) return response.json(); 
            if(response.status===400) return {error: "bad request"};
            if(response.status===401) return {error: "unauthorized"};
            return Promise.reject(response);
        })
        .then(json => {
            if(json.error){
                console.error(json.error);
                return;
            }
            setData(json);
            if(!json.is_dayoff){
                setTasks(json.data.tasks);
            }
        }).catch(response =>{
            console.log("catch");
            console.log(response);
            //setError(error);
        }).finally(() =>{
            setLoading(false);
        });
    }

    const handleClipboardRequest = () => {        
   
        let previousDayString = "";
        const date = getDateFromDDMMYYYY(data.data.previous_data.date);
        if(isYesterday(date)){
            previousDayString = "ayer"
        } else{
            previousDayString = "el " + getDayName(date);
        }
        
        let result = `Lo que hice ${previousDayString}:\n`

        for(let i=0;i<data.data.previous_data.tasks.length;i++){
            if(data.data.previous_data.tasks[i].status==1){
                result += "\t• " + data.data.previous_data.tasks[i].content + "\n";
            }
        }

        result += "\nHoy:\n";
        for(let i=0;i<tasks.length;i++){
            result += "\t• " + tasks[i].content + "\n";
        } 

        result += "\nSin impedimentos";

        console.log(result);
        
        navigator.clipboard.writeText(result);       
        
        setShowToast(true);
    }

    return (
        <>
            <DayHeader date={date} setDate={setDate} />
            {
                loading
                ? <div className="big-spinner fade-in-1">
                    <Spinner />
                    <div className="spinner-text">Cargando actividades...</div>
                </div>
                :
                (
                    data.is_dayoff
                    ? <DayoffDetails data={data.dayoff} />
                    : <>
                        <ActivitiesContainer date={date} activities={tasks} setActivities={setTasks} onClipboardRequest={handleClipboardRequest} />
                        <Dayoffs dayoffs={data.data.previous_data.dayoffs} />
                        <PreviousTasks dateString={data.data.previous_data.date} tasks={data.data.previous_data.tasks} />
                        <ToastContainer className="p-2" position={"bottom-center"} style={{ zIndex: 1 }}>
                            <Toast onClose={() => setShowToast(false)} show={showToast} delay={1000} autohide bg="dark">
                                <Toast.Body className={"text-white text-center"}>¡Copiado al portapapeles!</Toast.Body>
                            </Toast>
                        </ToastContainer>                        
                    </>
                )
            }
        </>
    );
}