import { useState } from "react";
import MessagesContainer from "./topic-messages/MessagesContainer";
import TopicsContainer from "./topics/TopicsContainer";

export default function MainContainer(){

    const [topicId, setTopicId] = useState(0);
    const [topicName, setTopicName] = useState("");

    const handleTopicRemoved = (oldTopicId) => {
        if(topicId===oldTopicId){
            setTopicId(0);
        }
    };

    const handleTopicNameChanged = (id, name) => {
        if(id===topicId){
            setTopicName(name);
        }
    };

    return (
        <div className="messages-main-container">
            <div className="topics">
                <TopicsContainer setTopicId={setTopicId} setTopicName={setTopicName} notifyTopicRemoved={handleTopicRemoved} notifyTopicNameChanged={handleTopicNameChanged} />     
            </div>
            <div className="messages">
                <MessagesContainer topicId={topicId} topicName={topicName} />
            </div>
        </div>
    );
}